.container {
    height: auto;
    overflow: hidden;
}

.krpano-container {
    height: 100vh;
    float: none;
    background: #e8f6fe;
    width: auto;
    overflow: hidden;
}

.krpano-container-full {
    width: 100%;
}

.menu-container {
    width: 180px;
    float: right;
    height: 100vh;
}

.menu-container-close {
    width: auto;
    height: auto;
    position: absolute;
    top: 3%;
    right: 3%;
    z-index: 1;
}

.toolbar-container {
    position: absolute;
}

.confirmation-container {

}

.hidden {
    display: none;
}

.form-container {
    width: 100%;
}

.scene-selector-container{
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
