.note {
    margin: 2rem;
    width: 100%;
    font-family: brandon_grotesqueregular;
    color: #5F5F5F;
    font-size: 1.6rem;
}

.note-title {
    font-weight: bolder;
}
.note-list {
    margin: 2rem;
}
