.confirmation {
    width: 100%;
    height: 100%;
    font-family: brandon_grotesqueregular;
    color: #5F5F5F;
    font-size: 1.6rem;
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    border-radius: 10px;
    text-align: center;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.confirmation-product-label {
    font-weight: bolder;
    font-size: 1.7rem;
}

.confirmation-button {
    width: 30%;
    justify-self: center;

    padding: 1rem;
    font-family: brandon_grotesqueregular;
    color: white;
    font-size: 1.6rem;
    border-style: solid;
    border-radius: 0.3rem;
    border-width: 0;
    margin: 2rem;

    min-width: 100px;
}

.confirmation-yes-button {
    background-color: #0069b4;
    border-color: #0069b4;
}

.confirmation-no-button {
    background-color: #6d7478;
    border-color: #6d7478;
}

.confirmation-yes-button:hover {
    background-color: #2d7ab1;
    border-color: #2d7ab1;
}

.confirmation-no-button:hover {
    background-color: #8c9a9e;
    border-color: #8c9a9e;
}
