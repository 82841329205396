.button {
    font-family: brandon_grotesqueregular;
    color: white;
    font-size: 1.6rem;
    background-color: #0069b4;
    border-style: solid;
    border-radius: 0.3rem;
    border-color: #0069b4;
    border-width: 0;
    text-align: center;
}

.button:hover {
    background-color: #2d7ab1;
    border-color: #2d7ab1;
    font-size: 1.7rem;
}

.button-calculate {
    background-image: url('../icon/balance-sheet.png');
    background-position: left;
    background-repeat: no-repeat;
    padding: 1rem 1rem 1rem 3.5rem;
}

.button-home {
    background-image: url('../icon/home.png');
    background-size: 45px 45px;
    background-position: left;
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
}

.toolbar-bottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toolbar-top {
    position: fixed;
    top: 3%;
    left: 3%;
    width: 50%;
}
