.options-container {
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.options {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    place-items: stretch stretch;
    place-content: stretch stretch;
    row-gap: 1rem;
    transition: left .5s, right .5s;
}
