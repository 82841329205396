/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 1, 2020 */



@font-face {
    font-family: 'brandon_grotesqueregular';
    src: url('brandon_reg-webfont.woff2') format('woff2'),
         url('brandon_reg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}