.menu {
    cursor: pointer;
    transition: right .5s;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.title {
    padding: 1.0rem;
    font-family: brandon_grotesqueregular;
    font-size: 1.7rem;
    color: #FFFFFF;
    background-color: #0069b4;
    text-align: center;
    border-style: solid;
    border-radius: 0.3rem;
    border-color: #0069b4;
    border-width: 0rem;
    min-height: 4.0rem;
}

.title .back{
    background-image: url("../icon/back.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 1.2rem 1.6rem;
    background-origin: content-box;
    width: 1.2rem;
    height: 1.6rem;
    position: absolute;
}

.title .close{
    background-image: url("../icon/close.png");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: 1.2rem 1.6rem;
    background-origin: content-box;
    width: 1.2rem;
    height: 1.6rem;
    position: absolute;
}

.menu-button {
    font-family: brandon_grotesqueregular;
    color: white;
    font-size: 1.6rem;
    background-color: #0069b4;
    border-style: solid;
    border-radius: 0.3rem;
    border-color: #0069b4;
    border-width: 0;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
}

.menu-button-hidden {
    display: none;
}
