.option {
    width: 100%;
    padding: 1.0rem;
    font-family: brandon_grotesqueregular;
    color: #0069b4;
    font-size: 1.6rem;
    border-style: solid;
    border-radius: 0.3rem;
    border-color: #0069b4;
    border-width: 0;
}

.option:hover {
    background-color: rgb(182, 210, 232);
}

.next {
    background-image: url("../icon/next.png");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 0.9rem 1.2rem;
    background-origin: content-box;
    width: 0.9rem;
    height: 1.2rem;
    position: relative;
    top: -50%;
    right: -90%;
}

.option.big {
    margin: 0;
}

.big .image {
    width: 100%;
}

.big .label {
    display: block;
    overflow: hidden;
    background-color: rgb(0, 105, 180);
    margin: 0;
    padding: 1.0rem;
    font-family: brandon_grotesqueregular;
    font-size: 1.2rem;
    color: #FFFFFF;
    text-align: center;
    min-height: 3.5rem;
}

.option.small {
    margin: 0 1.0rem;
}

.small .image {
    width: 2.5rem;
    vertical-align: middle;
}
