.form {
    width: 100%;
    font-family: brandon_grotesqueregular;
    color: #5F5F5F;
    font-size: 1.6rem;
    border-width: 1px;
    border-style: solid;
    padding: 1rem;
    border-radius: 10px;
    margin-top: 2rem;
}

.form-category-content {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 1rem;
    row-gap: 1rem;
    margin-left: 5rem;
}

.form-category-title {
    font-weight: bolder;
    font-size: 1.7rem;
}

.form-product-label {
    font-weight: bolder;
    grid-column: span 4;
}

.form-result {
    margin-left: 5rem;
}

.form-result ul {
    margin-left: 6rem;
    columns: 2;
}

.form-input-short {
    width: 50%;
    background-color: #F2EFEF;
    border-style: none;
    justify-self: start;
}

.form-input-large {
    width: 100%;
    background-color: #F2EFEF;
    border-style: none;
    justify-self: start;
    min-height: 3.2rem;
    font-size: 1.6rem;
}

.form-button {
    width: 30%;
    grid-column: span 4;
    justify-self: center;

    padding: 1rem;
    font-family: brandon_grotesqueregular;
    color: white;
    font-size: 1.6rem;
    background-color: #0069b4;
    border-style: solid;
    border-radius: 0.3rem;
    border-color: #0069b4;
    border-width: 0;
}

.form-button:hover {
    background-color: #2d7ab1;
    border-color: #2d7ab1;
    font-size: 1.7rem;
}

.form-input-error {
    background-image: url("../icon/warning.png");
    background-position: center center;
    background-repeat: no-repeat;
    width: 3.2rem;
    height: 3.2rem;
}

.form-input-icon-wrap {
    display: grid;
    grid-template-columns: 90% 10%;
}

.form-email-confirmation {
    width: 100%;
    font-family: brandon_grotesqueregular;
    color: #0069b4;
    text-align: center;
    font-size: 1.6rem;
}
