.selector{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.selector > * {
    margin: 0.5rem;
    padding: 0.5rem;
    text-align: center;
}
